<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการใช้บริการห้องตรวจ</h4>
      </div>

     <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filterData.search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วันที่บริการ"
                  placeholder="ระบุวันที่บริการ"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsDoctor"
              v-model="filterData.doctor"
              item-text="name"
              item-value="name"
              label="หมอ"
              placeholder="เลืือกหมอ"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsStatus"
              v-model="filterData.status"
              item-text="name"
              item-value="name"
              label="สถานะบริการ"
              placeholder="เลืือกสถานะบริการ"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.examinationroom_customer_name="{ item }">
        <div class="text-start">{{`${item.examinationroom_customer_name} (${item.examinationroom_customer_code})`}}</div>
      </template>
      <template v-slot:item.examinationroom_date="{ item }">{{moment(item.examinationroom_date).add(543, 'year').format('DD/MM/YYYY')}}</template>
      <template v-slot:item.examinationroom_moredetail="{ item }">
        <a v-if="item.examinationroom_moredetail" style="color: blue; text-decoration: underline;">ข้อมูลการใช้บริการ</a>
        <span v-else>ไม่มีข้อมูล</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-3"
          color="success"
          rounded
          @click="gotoinuseservice(item)"
        >ใช้บริการ</v-btn>
        <v-btn
          icon
          @click="deleteList(item)"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      loaderAdd: false,
      data: [
        {
          _id: '0001',
          no: 1,
          examinationroom_type: 'ใช้บริการ',
          examinationroom_date: moment(),
          examinationroom_customer_name: 'คุณเอ',
          examinationroom_customer_code: 'VIP0001',
          examinationroom_doctor: 'หมอหนึ่ง',
          examinationroom_room: 'ห้องตรวจ 1',
          examinationroom_status: 'รอใช้บริการ',
          examinationroom_moredetail: ''
        }, {
          _id: '0002',
          no: 2,
          examinationroom_type: 'ใช้บริการ',
          examinationroom_date: moment(),
          examinationroom_customer_name: 'คุณบี',
          examinationroom_customer_code: 'VIP0002',
          examinationroom_doctor: 'หมอหนึ่ง',
          examinationroom_room: 'ห้องตรวจ 2',
          examinationroom_status: 'รอใช้บริการ',
          examinationroom_moredetail: ''
        }, {
          _id: '0003',
          no: 3,
          examinationroom_type: 'ใช้บริการ',
          examinationroom_date: moment(),
          examinationroom_customer_name: 'คุณซี',
          examinationroom_customer_code: 'VIP0003',
          examinationroom_doctor: 'หมอหนึ่ง',
          examinationroom_room: 'ห้องตรวจ 1',
          examinationroom_status: 'รอใช้บริการ',
          examinationroom_moredetail: ''
        }, {
          _id: '0004',
          no: 4,
          examinationroom_type: 'ใช้บริการ',
          examinationroom_date: moment(),
          examinationroom_customer_name: 'คุณดี',
          examinationroom_customer_code: 'VIP0004',
          examinationroom_doctor: 'หมอหนึ่ง',
          examinationroom_room: 'ห้องตรวจ 2',
          examinationroom_status: 'รอใช้บริการ',
          examinationroom_moredetail: ''
        }
      ],
      itemsDoctor: [{
        _id: '00000',
        name: 'หมอหนึ่ง',
        value: 'หมอหนึ่ง'
      }, {
        _id: '00001',
        name: 'หมอสอง',
        value: 'หมอสอง'
      }],
      itemsStatus: [{
        _id: '00000',
        name: 'รอปรึกษา',
        value: 'waiting for advice'
      }, {
        _id: '00001',
        name: 'รอตรวจ',
        value: 'waiting for examination'
      }, {
        _id: '00002',
        name: 'รอใช้บริการ',
        value: 'waiting for service'
      }, {
        _id: '00003',
        name: 'ตรวจแล้ว',
        value: 'complete'
      }],
      menuSelectDate: false,
      filterData: {
        date: new Date().toISOString().substr(0, 10),
        search: '',
        doctor: '',
        status: ''
      },
      menuFilterDate: false
    }
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    },
    gotoinuseservice (item) {
      const self = this
      setTimeout(() => {
        self.$router.push({ name: 'ServiceExaminationRoomScreen', params: { dataItem: item } })
      }, 200)
    },
    addNewQueue () {
      const self = this
      self.loaderAdd = true
      swal('สำเร็จ', 'เพิ่มคิวใช้บริการสำเร็จ', 'success', {
        button: false,
        timer: 3000
      }).then(() => {
        self.loaderAdd = false
        self.initData()
      })
    },
    inService (item) {
      const self = this
      self.dialogData = item
      self.loaddata = true
      swal('สำเร็จ', 'ใช้บริการสำเร็จ', 'success', {
        button: false,
        timer: 3000
      }).then(() => self.initData())
    },
    deleteList (item) {
      const self = this
      self.loaddata = true
      var itemindex = null
      itemindex = self.data.findIndex(el => {
        return el._id === item._id
      })
      swal(`ต้องการลบรายการ คนไข้ "${item.examinationroom_customer_name} (${item.examinationroom_customer_code})" ในสถานะ "${item.examinationroom_status}" ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.data.splice(itemindex, 1)
            self.loaddata = false
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
          self.initData()
        }
      })
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'ชื่อผู้ใช้บริการ (รหัสลูกค้า)',
          align: 'center',
          value: 'examinationroom_customer_name',
          sortable: false
        }, {
          text: 'หมอผู้ตรวจ',
          align: 'center',
          value: 'examinationroom_doctor',
          sortable: false
        }, {
          text: 'วันที่บันทึก',
          align: 'center',
          value: 'examinationroom_date',
          sortable: false
        }, {
          text: 'ห้อง',
          align: 'center',
          value: 'examinationroom_room',
          sortable: false
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'examinationroom_status',
          sortable: false
        }, {
          text: 'การจัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    }
  }
}
</script>
