var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("รายการใช้บริการห้องตรวจ")])]),_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4"}},[_c('v-text-field',{attrs:{"label":"พิมพ์คำค้นหา","single-line":"","hide-details":""},model:{value:(_vm.filterData.search),callback:function ($$v) {_vm.$set(_vm.filterData, "search", $$v)},expression:"filterData.search"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"#182839"},slot:"prepend"},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('v-menu',{ref:"menuFilterDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedFilterDateFormatted,"label":"วันที่บริการ","placeholder":"ระบุวันที่บริการ","append-outer-icon":"mdi-calendar-month","readonly":"","required":""},on:{"click:append-outer":function($event){_vm.menuFilterDate = true}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFilterDate),callback:function ($$v) {_vm.menuFilterDate=$$v},expression:"menuFilterDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.menuFilterDate = false}},model:{value:(_vm.filterData.date),callback:function ($$v) {_vm.$set(_vm.filterData, "date", $$v)},expression:"filterData.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.itemsDoctor,"item-text":"name","item-value":"name","label":"หมอ","placeholder":"เลืือกหมอ","no-data-text":"ยังไม่มีข้อมูล","single-line":"","hide-details":""},model:{value:(_vm.filterData.doctor),callback:function ($$v) {_vm.$set(_vm.filterData, "doctor", $$v)},expression:"filterData.doctor"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.itemsStatus,"item-text":"name","item-value":"name","label":"สถานะบริการ","placeholder":"เลืือกสถานะบริการ","no-data-text":"ยังไม่มีข้อมูล","single-line":"","hide-details":""},model:{value:(_vm.filterData.status),callback:function ($$v) {_vm.$set(_vm.filterData, "status", $$v)},expression:"filterData.status"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item.examinationroom_customer_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-start"},[_vm._v(_vm._s(((item.examinationroom_customer_name) + " (" + (item.examinationroom_customer_code) + ")")))])]}},{key:"item.examinationroom_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.examinationroom_date).add(543, 'year').format('DD/MM/YYYY')))]}},{key:"item.examinationroom_moredetail",fn:function(ref){
var item = ref.item;
return [(item.examinationroom_moredetail)?_c('a',{staticStyle:{"color":"blue","text-decoration":"underline"}},[_vm._v("ข้อมูลการใช้บริการ")]):_c('span',[_vm._v("ไม่มีข้อมูล")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"color":"success","rounded":""},on:{"click":function($event){return _vm.gotoinuseservice(item)}}},[_vm._v("ใช้บริการ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteList(item)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }